<template>
  <div id="pdfvuer">
    <div v-if="numPages > 0">
      <template v-for="i in numPages">
        <pdf
          :src="pdfData"
          :key="i"
          :id="i"
          :page="i"
          :scale.sync="scale"
          :annotation="true"
          :resize="true"
          class="pdf-viewer"
        >
        </pdf>
      </template>
    </div>
    <div v-else class="d-flex justify-center align-center">
      <v-img
        max-height="100"
        max-width="100"
        src="/assets/image/logo-animation.gif"
      ></v-img>
    </div>
  </div>
</template>

<script>
  import pdfvuer from 'pdfvuer';

  export default {
    name: 'AppPDFViewer',
    components: {
      pdf: pdfvuer
    },
    props: {
      fileSource: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      page: 1,
      numPages: 0,
      pdfData: undefined,
      scale: 'page-width'
    }),
    mounted() {
      this.getPdf();
    },
    methods: {
      getPdf() {
        let vm = this;

        vm.pdfData = pdfvuer.createLoadingTask(this.fileSource);
        vm.pdfData.then((pdf) => {
          vm.numPages = pdf.numPages;
        });
      }
    }
  };
</script>

<style lang="scss">
  .pdf-viewer {
    width: 100%;
  }
</style>
