<template>
  <PageLayout
    :bannerSrc="bannerSrc"
    :pageTitle="pageTitle"
    :pageSubtitle="pageSubtitle"
    pageTitleDividerClass="secondary"
    :color="color"
    :hideLogo="true"
    layoutType="a"
  >
    <div class="content-wrapper pt-6 pb-12">
      <div
        class="support-page-template__topic font-weight-bold text-h6 text-md-h5 text-xl-h4"
        :class="[{ 'text-capitalize': topic == 'privacyPolicy' }]"
      >
        {{ $t(`label.${topic}`) }}
      </div>
      <v-card
        height="auto"
        :class="isPDF ? 'pa-4 pa-sm-6' : 'pa-6 pa-sm-8'"
        class="rounded-xl pa-md-12"
      >
        <div v-if="isPDF">
          <AppPDFViewer
            v-if="!noPDFContent"
            :fileSource="fileSource"
          ></AppPDFViewer>
          <div v-else class="text-center">
            {{ $t('message.sorryThereIsNoContentAtTheMomentPleaseStayTuned') }}
          </div>
        </div>
        <div v-else v-html="content"></div>
      </v-card>
    </div>
  </PageLayout>
</template>

<script>
  import axios from 'axios';
  import PageLayout from '@/views/layout/PageLayout';
  import AppPDFViewer from '@/views/components/base/AppPDFViewer';
  import { Configuration, uiHelper } from '@/core/utils';

  export default {
    name: 'SupportPageTemplate',
    components: {
      PageLayout,
      AppPDFViewer
    },
    props: {
      bannerSrc: {
        type: String,
        default: ''
      },
      pageTitle: {
        type: String,
        default: ''
      },
      pageSubtitle: {
        type: String,
        default: ''
      },
      color: {
        type: String,
        default: 'surface'
      },
      topic: {
        type: String,
        default: ''
      },
      isPDF: {
        type: Boolean,
        default: false
      },
      content: {
        type: String,
        default: ''
      },
      isCreator: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      spacesUrl: Configuration.value('spacesUrl'),
      assetEnvironment: Configuration.value('assetEnvironment'),
      noPDFContent: false
    }),
    computed: {
      platformInfo() {
        return this.$store.state.platformInfo.platformInfo;
      },
      fileSource() {
        let source = {
          faqs: 'faqs',
          termsOfUse: this.isCreator ? 'creator_terms' : 'member_terms',
          privacyPolicy: 'privacy_policy',
          termsAndConditions: 'terms_condition'
        };

        // Insert random numbers to prevent content being cached
        let randomNumber =
          'random=' +
          new Date().getTime() +
          Math.floor(Math.random() * 1000000);
        // https://cookx.sgp1.digitaloceanspaces.com/dev/
        return `${this.spacesUrl}/${this.assetEnvironment}/${
          source[this.topic]
        }.pdf?${randomNumber}`;
      }
    },
    watch: {
      isCreator() {
        uiHelper.reloadPage();
      }
    },
    async created() {
      await this.initSupportPageTemplate();
    },
    methods: {
      async examinePDF() {
        let url = this.fileSource;
        let vm = this;

        await axios
          .get(url)
          .then(function (response) {
            if (response.status == 200) {
              vm.noPDFContent = false;
            }
          })
          .catch(function () {
            vm.noPDFContent = true;
          });
      },
      async initSupportPageTemplate() {
        if (this.isPDF) {
          await this.examinePDF();
        }
      }
    }
  };
</script>

<style lang="scss">
  .support-page-template__topic {
    color: var(--v-surface-base);
    font-weight: 700;
    padding: 32px 40px 32px 40px;
  }

  @media (max-width: 1263px) {
    .support-page-template__topic {
      padding: 24px 32px 24px 32px;
    }
  }

  @media (max-width: 959px) {
    .support-page-template__topic {
      padding: 12px 24px 12px 24px;
    }
  }
</style>
